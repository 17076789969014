import React, { useState, useEffect, useRef, useCallback } from "react";
import "./serachpackage.css";
import { Link } from "react-router-dom";
import { AllPackages } from "../../Api/packages";
import Calender from "../../assets/calender.svg";
import City from "../../assets/city.svg";
import Location from "../../assets/location.svg";
import SkeletonLoader from "../../Components/Skeleton_loader/skeleton";
import { FaChevronDown } from "react-icons/fa6";
import Accordion from 'react-bootstrap/Accordion';
import { AllCountry } from "../../Api/destinations";

export default function AllPackage() {
    const [hotdeals, setHotdeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleFetch = useCallback((data) => {
        setHotdeals(data || []);
        setLoading(false);
    }, []);
    useEffect(() => {
        setLoading(true);
    }, []);
    /* ---------------Short by disply---------------*/
    const [isDivVisible, setDivVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const divRef = useRef(null);
    const handleClick = () => {
        if (isDivVisible) {
            setIsClosing(true);
            setTimeout(() => {
            setIsClosing(false);
            setDivVisible(false);
          }, 200);
        } else {
            setDivVisible(true);
            setIsActive(true);
            setTimeout(() => {
                setIsActive(false);
            }, 200);
        }
    };
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setDivVisible(false);
                document.body.classList.remove('no-scroll');
                setIsSidebarOpen(false);
            }, 200);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    /*-------------------Country API ----------------*/
    const [Countylist, setCountries] = useState([]);
    /*---------------Filter Popup---------------------*/
    const [isVisible, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => {
            const newState = !prev;
            document.body.classList.toggle('no-scroll', newState);
            return newState;
        });
    };
    /*---------------Filter apply------------*/
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [sortOrder, setSortOrder] = useState('0');
    const handleCheckboxChange = (event, countryName) => {
        const updatedCountries = event.target.checked
            ? [...selectedCountries, countryName]
            : selectedCountries.filter(name => name !== countryName);
        setSelectedCountries(updatedCountries);
        updateUrl(updatedCountries, sortOrder);
    };
    const handleSortChange = (newSortOrder) => {
        setSortOrder(newSortOrder === '0' ? '' : newSortOrder);
        updateUrl(selectedCountries, newSortOrder === '0' ? '' : newSortOrder);
        handleClickOutside(false);
    };
    const updateUrl = (countries, order) => {
        const countryParams = countries.length > 0 ? `countries=${countries.map(encodeURIComponent).join('%2C')}` : '';
        const sortOrderParam = (order && order !== '0') ? `sortOrder=${encodeURIComponent(order)}` : '';
        const queryParams = [countryParams, sortOrderParam].filter(Boolean).join('&');
        const fullUrl = queryParams ? `/packages/?${queryParams}` : '/packages/';
        window.history.replaceState(null, '', fullUrl);
    };
    useEffect(() => {
        if (!sortOrder) {
            setSortOrder('0');
        }
    }, [sortOrder]);
    return (
        <>
            <AllPackages onFetch={handleFetch} />
            <AllCountry onFetch={setCountries} />
            <section className="searchfiltter">
                <div className="container-fluid">
                    <div className="w90">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                                <div className="searchresult">
                                    <div className="resultsearch">
                                        <p>Search</p>
                                    </div>
                                    <div className="resultsearchounty">
                                        <p>{hotdeals.package_count} Packages found</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                                <div className="shoringlist">
                                    <div className="sortbyfilter">
                                        <div className="shortfilertlistara">
                                            <div className="shortbytext" onClick={handleClick}>
                                                <p>Sort By:</p>
                                                <p>
                                                    {sortOrder === '0' ? 'Most Relevant' : ''}
                                                    {sortOrder === '1' ? 'Price - High to Low' : ''}
                                                    {sortOrder === '2' ? 'Price - Low to High' : ''}
                                                </p>
                                                <div className="shortarrow"><FaChevronDown/></div>
                                            </div>
                                            {isDivVisible && (
                                                <div ref={divRef} className={`shrotbylist ${isDivVisible ? "visible" : "hidden"} ${ isActive ? "isactive" : "" } ${isClosing ? "closingdiv" : ""}`}>
                                                    <div className="shortlistby">
                                                        <ul>
                                                            <li className={!sortOrder || sortOrder === '0' ? 'active' : ''} onClick={() => handleSortChange('0')}>Most Relevant</li>
                                                            <li class={sortOrder === '1' ? 'active' : ''} onClick={() => handleSortChange('1')}>Price - High to Low</li>
                                                            <li class={sortOrder === '2' ? 'active' : ''} onClick={() => handleSortChange('2')}>Price - Low to High</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="fillterbtns">
                                            <div className="filtterbtntext" onClick={toggleSidebar}>
                                                <p>Filter</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fillterrestbtn">
                                        <p>Reset</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="packagelist">
                <div className="container-fluid">
                    <div className="w90">
                        {!loading ? (
                            <div className="row">
                                {hotdeals.packages.map((hotdeal) => hotdeal && (
                                    <div className="packagelistgrid" key={hotdeal.id}>
                                        <div className="newhotdelsgrid">
                                            <div className="hotdealsnewgimg">
                                                <Link to={hotdeal.package_url+'/?from=uk'} target="_blank">
                                                    <img src={hotdeal.package_img} alt={hotdeal.package_name} />
                                                </Link>
                                            </div>
                                            <div className="hotgridcheading">
                                                <h2>
                                                    <Link to={hotdeal.package_url+'/?from=uk'} target="_blank">{hotdeal.package_name}</Link>
                                                </h2>
                                            </div>
                                            <div className="hotgridmeta">
                                                <ul>
                                                    <li>
                                                        <img src={Calender} alt="calender" />
                                                        <span className="metacontens">{hotdeal.day_nights}</span>
                                                    </li>
                                                    <li>
                                                        <img src={City} alt="city" />
                                                        <span className="metacontens">
                                                            {hotdeal.total_city > 1 ? `${hotdeal.total_city} Cities` : `${hotdeal.total_city} City`}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <img src={Location} alt="location" />
                                                        <span className="metacontens">
                                                            {hotdeal.total_country > 1 ? `${hotdeal.total_country} Countries` : `${hotdeal.total_country} Country`}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="hotgridshort">
                                                <ul>
                                                    {hotdeal.package_display.split(',').map((packageItem, subIndex) => {
                                                        if (packageItem === 'hot_deals') {
                                                            return (
                                                                <li className="hotdealshots" key={`${hotdeal.id}-${subIndex}`}><Link to="#">Hot Deals</Link></li>
                                                            );
                                                        }
                                                        if (packageItem === 'luxury_holiday') {
                                                            return (
                                                                <li key={`${hotdeal.id}-${subIndex}`}><Link to="#">Luxury Holiday</Link></li>
                                                            );
                                                        }
                                                        if (packageItem === 'short_break') {
                                                            return (
                                                                <li key={`${hotdeal.id}-${subIndex}`}><Link to="#">Short Breaks</Link></li>
                                                            );
                                                        }
                                                        if (packageItem === 'adventure_packages') {
                                                            return (
                                                                <li key={`${hotdeal.id}-${subIndex}`}><Link to="#">Adventure</Link></li>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="hotgridcontent">
                                                <p dangerouslySetInnerHTML={{ __html: hotdeal.package_content+'...' || '' }}></p>
                                            </div>
                                            <div className="hotgridpricebtn">
                                                <div className="hotgridpricebtns">
                                                    <div className="pricehotgrid">
                                                        {hotdeal.sale_price ? (
                                                            <>
                                                                <small>
                                                                    From <span><del>GBP <span className="pricelcous">£ {hotdeal.regular_price}</span></del></span>
                                                                </small>
                                                                <h5>GBP £ {hotdeal.sale_price} pp</h5>
                                                            </>
                                                        ) : (
                                                            <h5>GBP £ {hotdeal.regular_price} pp</h5>
                                                        )}
                                                    </div>
                                                    <div className="btnshotdel">
                                                        <Link to={hotdeal.package_url+'/?from=uk'} target="_blank">More Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <SkeletonLoader count={4} />
                        )}
                    </div>
                </div>
            </section>
            {isVisible && (
                <div className={`filterpopup ${isVisible ? 'filteropen' : 'filterclose'}`}>
                    <div className="filterpopupdata" ref={divRef} >
                        <div className="filterheaidng">
                            <h2>Filter</h2>
                            <div className="popuprest">
                                <button type="button">Reset</button>
                            </div>
                        </div>
                        <div className="filtertabs">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Country</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="countrylistapi">
                                            {Countylist.map((Countylists, index) => Countylists && (
                                                <div className="formgroup" key={Countylists.id}>
                                                    <input type="checkbox" className="countryfilter" name="countryname" onChange={(event) => handleCheckboxChange(event, Countylists.country_name)} id={"countryname"+index} />
                                                    <label htmlFor={"countryname"+index}>{Countylists.country_name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How Long For?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>   
            )}
        </>
    );
}